import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

import HelmetWrapper from "./helmet";
import PageContainer from "./page-container";
import { HamburgerSvg } from "../common/Svgs";
import Footer from "./footer";

import '../../scss/styles.scss';

export default class ExhibitLayout extends React.Component {
  mobileNavRef = React.createRef();
  hamburgerRef = React.createRef();

  render() {
    return (
      <StaticQuery
        query={graphql`
          query ExhibitLayoutQuery {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `}
        render={data => (
          <div>
            <HelmetWrapper site={data.site} />
            <div className="page">
              <div className="header-wrapper">
                <header className="exhibit">
                  <div className="header-contents wrapper-width">
                    <Link className="home-block" to="/">
                      <span className="label">
                        <span className="cap">LM</span>
                      </span>
                    </Link>
                    <div className="title-block">
                      {this.props.pageContext.short_title}: <span className="subtitle">{this.props.pageContext.subtitle}</span>
                    </div>
                    <nav className="header-nav">
                      <ul>
                        <li><Link to="/notes">Blog</Link></li>
                        <li><Link to="/about">About</Link></li>
                      </ul>
                    </nav>
                    <button
                      className="mobile-nav-launcher"
                      ref={ this.hamburgerRef }
                      onClick={(e) => {
                        const mobileNav = this.mobileNavRef.current;
                        const hamburgerButton = this.hamburgerRef.current;

                        if (mobileNav.classList.contains('expanded')) {
                          hamburgerButton.classList.remove('expanded');
                          mobileNav.classList.remove('expanded');
                        } else {
                          hamburgerButton.classList.add('expanded');
                          mobileNav.classList.add('expanded');
                        }
                      }}
                    >
                      { HamburgerSvg }
                    </button>
                  </div>
                </header>
                <div
                  className="mobile-header"
                  ref={this.mobileNavRef}
                >
                  <ul>
                    <li><Link to="/notes">Blog</Link></li>
                    <li><Link to="/about">About</Link></li>
                  </ul>
                </div>
              </div>
              <PageContainer bottomPadding="lg">
                {this.props.children}
              </PageContainer>
            </div>
            <Footer site={data.site} />
          </div>
        )}
      />
    )
  }
}
