import React from "react";

import { Link, StaticQuery, graphql } from "gatsby";

class NarrativeSummaryCell extends React.Component {
  constructor(props) {
    super(props);

    this.featuredImagePath = this.featuredImagePath.bind(this);
  }

  featuredImagePath(allImages) {
    const featuredImage = allImages.edges.filter(({ node }) => node.reference === this.props.narrativeNode.featuredImage)[0];

    return featuredImage ? featuredImage.node.thumbPath : '';
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allImagesJson {
              edges {
                node {
                  reference
                  thumbPath
                  fullPath
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <li style={{ backgroundImage: `url('/images/content/${this.featuredImagePath(data.allImagesJson)}')`}}>
              <div className="overlay">
                <h3>
                  <Link to={`/${this.props.narrativeNode.exhibit}/narratives/${this.props.narrativeNode.reference}`}>
                    {this.props.narrativeNode.title}
                  </Link>
                </h3>
                <div className="details">
                  <p>
                    {this.props.narrativeNode.blurb}
                  </p>
                  <p>
                    <Link to={`/${this.props.narrativeNode.exhibit}/narratives/${this.props.narrativeNode.reference}`}>Read More…</Link>
                  </p>
                </div>
              </div>
            </li>
          );
        }}
      />
    );
  }
}

export default NarrativeSummaryCell;
