import React from "react";
import { Link, graphql } from "gatsby";
import ExhibitLayout from "../../components/layout/layout-exhibit";

import Helmet from 'react-helmet';

import PageSection from "../../components/layout/page-section";
import MultipleVenueMap from "../../components/maps/multiple-venue-map";
import ListingGridItem from "../../components/browser/listing-grid-item";
import NarrativeSummaryCell from "../../components/exhibits/narrative-summary-cell";

import "../../scss/pages/exhibits/athens-on-the-colorado.scss";

export default class AthensExhibitLanding extends React.Component {
  constructor(props) {
    super(props);

    this.featuredVenueReferences = [
      'austin-country-club',
      'driskill-hotel',
      'stephen-f-austin-hotel',
      'federated-womens-club-building',
      'gregory-gym',
      'texas-union',
      'royal-auditorium',
      'cotton-club',
      'paramount'
    ];

    this.featuredArtistReferences = [
      'louis-armstrong',
      'duke-ellington-and-his-orchestra',
      'paul-whiteman-and-his-orchestra',
      'jimmie-lunceford-and-his-orchestra',
      'benny-goodman-and-his-orchestra',
      'ben-young-and-his-music',
      'fred-gardner-and-his-troubadours',
      'steve-gardner-and-his-hokum-kings',
      'carnes-weaver-and-his-orchestra',
      'johnnie-simmons-and-his-orchestra',
      'george-corley-and-his-royal-aces',
      'tommy-brooks-and-his-orchestra'
    ];

    this.featuredVenues = this.featuredVenues.bind(this);
    this.featuredArtists = this.featuredArtists.bind(this);

    this.universityNarratives = this.universityNarratives.bind(this);
    this.popMusicNarratives = this.popMusicNarratives.bind(this);
  }

  featuredVenues() {
    const venues = this.props.data.allVenuesJson.edges.filter(({ node }) => this.featuredVenueReferences.includes(node.reference));

    return venues.sort((a, b) => {
      return this.featuredVenueReferences.indexOf(a.node.reference) - this.featuredVenueReferences.indexOf(b.node.reference);
    });
  }

  featuredArtists() {
    const artists = this.props.data.allArtistsJson.edges.filter(({ node }) => this.featuredArtistReferences.includes(node.reference));

    return artists.sort((a, b) => {
      return this.featuredArtistReferences.indexOf(a.node.reference) - this.featuredArtistReferences.indexOf(b.node.reference);
    });
  }

  universityNarratives() {
    const references = [
      'texas-union',
      'ut-dances',
      'artist-series',
      'ellington-whiteman',
      'photos-of-the-all-university-dances'
    ];
    const narratives = this.props.data.allNarrativesJson.edges.filter(({ node }) => references.includes(node.reference));

    return narratives.sort((a, b) => {
      return references.indexOf(a.node.reference) - references.indexOf(b.node.reference);
    });
  }

  popMusicNarratives() {
    const references = [
      'territory-bands',
      'national-bands',
      'east-austin-centers-of-swing',
      'swing-in-austin',
      'visualizing-the-economics-of-music-in-austin',
      'gene-ramey',
    ];
    const narratives = this.props.data.allNarrativesJson.edges.filter(({ node }) => references.includes(node.reference));

    return narratives.sort((a, b) => {
      return references.indexOf(a.node.reference) - references.indexOf(b.node.reference);
    });
  }

  render() {
    return (
      <ExhibitLayout pageContext={this.props.pageContext}>
        <Helmet>
          <title>Athens on the Colorado: The Dominance of the Universities, 1929-1946</title>
          <meta name="title" content="Athens on the Colorado: The Dominance of the Universities, 1929-1946" />
          <meta name="description" content="Although not typically given much attention, Austin's music scene during the 1930s and early 1940s was unexpectedly vibrant for the modest size of the city at the time. It was a pop music world whose character largely reflected an emphasis on modern sophistication, dance orchestras, universities, and segregation." />

          <meta property="og:type" content="article" />
          <meta property="og:url" content="https://local-memory.org/athens-on-the-colorado/" />

          <meta property="og:title" content="Athens on the Colorado: The Dominance of the Universities, 1929-1946" />
          <meta property="og:description" content="Although not typically given much attention, Austin's music scene during the 1930s and early 1940s was unexpectedly vibrant for the modest size of the city at the time. It was a pop music world whose character largely reflected an emphasis on modern sophistication, dance orchestras, universities, and segregation." />
          <meta property="og:image" content="https://local-memory.org/images/content/e/Exhibit_Athens.jpg" />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://local-memory.org/athens-on-the-colorado/" />
          <meta property="twitter:title" content="Athens on the Colorado: The Dominance of the Universities, 1929-1946" />
          <meta property="twitter:description" content="Although not typically given much attention, Austin's music scene during the 1930s and early 1940s was unexpectedly vibrant for the modest size of the city at the time. It was a pop music world whose character largely reflected an emphasis on modern sophistication, dance orchestras, universities, and segregation." />
          <meta property="twitter:image" content="https://local-memory.org/images/content/e/Exhibit_Athens.jpg" />
        </Helmet>
        <div className="athens-exhibit-special-layout">
          <PageSection background="gray" wrapperType="wrapper" bottomPadding="md">
            <div className="intro-row">
              <div className="intro-text-group">
                <div className="featured-thumb">
                  <img src="/images/content/e/Exhibit_Athens_thumb.jpg" alt="Hogg Auditorium" />
                  <div className="caption-block">
                    <span className="caption">Hogg Auditorium, University of Texas Campus, Ellison Photo Company, February 2, 1934</span>
                    <span className="citation">Austin History Center, Austin Public Library CO6812</span>
                  </div>
                </div>
                <div className="text-block">
                  <h2>
                    <Link to="/athens-on-the-colorado/narratives/athens-on-the-colorado">
                      Introduction: Athens on the Colorado
                    </Link>
                  </h2>
                  <p className="intro">
                    Although not typically given much attention, Austin's music scene during the 1930s and early 1940s was unexpectedly vibrant for the modest size of the city at the time. It was a pop music world whose character largely reflected an emphasis on modern sophistication, dance orchestras, universities, and segregation. As a place with a consistent, large student audience, Austin had numerous local orchestras during these decades and also attracted major national “star” talent. Beyond sweet and swing bands, Depression-era Central Texas supported a wide and varied environment of informal and vernacular performers and music as well, including jubilee quartets, cowboy songs, and street corner blues singers.
                  </p>
                  <p className="intro">
                    <Link to="/athens-on-the-colorado/narratives/athens-on-the-colorado" className="read-more">Read more…</Link>
                  </p>
                </div>
              </div>
              <div className="interview-group">
                <div className="feature-box">
                  <h3>
                    <Link to="/athens-on-the-colorado/narratives/austin-1900-1940">Austin, 1900-1940: Urban Planning, the Hill Country Environment, and Jim Crow Segregation</Link>
                  </h3>
                  <p>
                    Listen to Andrew Busch, author of <i>City in a Garden</i>, speak extensively about Austin’s urban history, its investment in its arid but beautiful environment, and its history of segregation.
                  </p>
                  <ul className="links">
                    <li><Link to="/athens-on-the-colorado/narratives/austin-1900-1940">Introduction</Link></li>
                    <li><Link to="/athens-on-the-colorado/narratives/austin-1900-1940#jim-crow">Jim Crow</Link></li>
                    <li><Link to="/athens-on-the-colorado/narratives/austin-1900-1940#urban-planning">Urban Planning</Link></li>
                    <li><Link to="/athens-on-the-colorado/narratives/austin-1900-1940#new-deal-austin">New Deal Austin</Link></li>
                    <li><Link to="/athens-on-the-colorado/narratives/austin-1900-1940#african-american-cultural-centers">African American Cultural Centers</Link></li>
                    <li><Link to="/athens-on-the-colorado/narratives/austin-1900-1940#mexican-americans">Mexican Americans</Link></li>
                    <li><Link to="/athens-on-the-colorado/narratives/austin-1900-1940#the-environment">The Environment</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </PageSection>
          <PageSection background="dark-gray" wrapperType="wrapper">
            <h3 className="section-header">Music at the Universities</h3>
          </PageSection>
          <PageSection background="dark-gray" wrapperType="wrapper" bottomPadding="md">
            <ul className="exhibit-narrative-grid">
              {this.universityNarratives().map(({ node }, idx) =>(
                <NarrativeSummaryCell key={idx} narrativeNode={node} />
              ))}
            </ul>
          </PageSection>
          <PageSection background="dark-gray" wrapperType="wrapper">
            <h3 className="section-header">Popular and Vernacular Music in Austin</h3>
          </PageSection>
          <PageSection background="dark-gray" wrapperType="wrapper" bottomPadding="md">
            <ul className="exhibit-narrative-grid">
              {this.popMusicNarratives().map(({ node }, idx) => (
                <NarrativeSummaryCell key={idx} narrativeNode={node} />
              ))}
            </ul>
          </PageSection>
          <PageSection topPadding="md" bottomPadding="md">
            <h2>Featured Venues</h2>
            <div className="multiple-venue__map-wrapper">
                <MultipleVenueMap
                  featuredVenues={this.featuredVenueReferences}
                  allVenues={this.props.data.allVenuesJson.edges}
                  allNarratives={this.props.data.allNarrativesJson.edges}
                />

            </div>
          </PageSection>

          <PageSection bottomPadding="md">
            <ul className="listing-grid">
              {this.featuredVenues().map(({ node }) => (
                <ListingGridItem
                  key={node.reference}
                  label={node.canonical_name}
                  target={`/venues/${node.reference}`}
                />
              ))}
            </ul>
          </PageSection>

          <PageSection>
            <h2>Featured Artists</h2>
            <ul className="listing-grid">
              {this.featuredArtists().map(({ node }) => (
                <ListingGridItem
                  key={node.reference}
                  label={node.canonical_name}
                  target={`/artists/${node.reference}`}
                />
              ))}
            </ul>
          </PageSection>
        </div>
      </ExhibitLayout>
    );
  }
}

export const query = graphql`
  query AthensExhibitQuery {
    site {
      siteMetadata {
        title
      }
    }
    allNarrativesJson {
      edges {
        node {
          reference
          exhibit
          title
          featuredImage
          blurb
          summary
          venues
        }
      }
    }
    allVenuesJson {
      edges {
        node {
          reference
          canonical_name
          locations {
            coordinates {
              lat
              lon
            }
          }
        }
      }
    }
    allArtistsJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    }
  }
`
