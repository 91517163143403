import React from "react";
import { Link } from "gatsby";

import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import iconShadow from "leaflet/dist/images/marker-shadow.png";

class MultipleVenueMap extends React.Component {
  constructor(props) {
    super(props);

    this.position = [30.266666667, -97.7333333];
    this.zoom = 11;

    this.mapMarkers = this.mapMarkers.bind(this);
    this.narrativeList = this.narrativeList.bind(this);
  }

  narrativeList(venueReference) {
    const narratives = this.props.allNarratives.filter(({ node }) => {
      return node.venues && node.venues.includes(venueReference);
    });

    return (
      <>
        <h4>Read More…</h4>
        <ul>
          {narratives.map(({ node }, idx) => (
            <li key={idx}>
              <Link to={`/athens-on-the-colorado/narratives/${node.reference}`}>
                {node.title}
              </Link>
            </li>
          ))}
        </ul>
      </>
    );
  }

  mapMarkers() {
    return (
      <>
        {this.props.featuredVenues.map((reference, idx) => {
          const venue = this.props.allVenues.filter(({ node }) => node.reference === reference)[0].node;
          
          if (venue.locations && venue.locations.length > 0) {
            return (
              <Marker
                key={idx}
                position={[venue.locations[0].coordinates.lat, venue.locations[0].coordinates.lon]}
              >
                <Popup>
                  <h3>
                    {venue.canonical_name}
                  </h3>
                  <div className="content-card">
                    <p>
                      <Link to={`/venues/${venue.reference}`}>
                        Venue Details
                      </Link>
                    </p>
                    {this.narrativeList(venue.reference)}
                  </div>
                </Popup>
              </Marker>
            );
          }

          return null;
        })}
      </>
    );
  }

  componentDidMount() {
    let L = require('leaflet');

    let DefaultIcon = L.icon({
      iconUrl: "/images/interface/maps/marker.png",
      iconRetinaUrl: "/images/interface/maps/marker-2x.png",
      iconSize: [25,43],
      iconAnchor: [12.5, 43],
      popupAnchor: [1, -24],
      shadowUrl: iconShadow,
    });

    L.Marker.prototype.options.icon = DefaultIcon;
  }

  render() {
    if (typeof window !== 'undefined') {
      return (
        <Map
          className="map-container"
          center={this.position}
          zoom={this.zoom}
          minZoom={7}
          maxZoom={18}
          maxBounds={[[33.3, -99], [29.2, -94.9]]}
          scrollWheelZoom={false}
        >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={18}
            attribution={'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'}
          />
          {this.mapMarkers()}
        </Map>
      );
    }

    return null;
  }
}

export default MultipleVenueMap;